import React from 'react';
import "../../styles/projectCard.css";

export default function ProjectCard({project}) {
  const title = project.title ?? "Title";
  const tags = project.tags ?? ["Tag 1", "Tag 2"];
  const description = project.description ?? "This is the default description.";
  const grade = project.grade ?? null;
  const image = project.image ?? null;
  const github = project.github ?? null;

  const hasHtmlTags = /<[a-z][\s\S]*>/i.test(description);

  return (
    <div className="col-md-6 col-lg-4 card-container animate-box">
      <div className="card">
        {image && <div className="card-img">
          <img src={image} alt="Project"></img>
        </div>}
        <div className="header">
          <h3> {title} </h3>
          {grade && <span className="grade" title="This project was graded as part of a university course."> {grade} </span>}
          </div>
        <div className="tags">
          {tags.map(tag => 
            <span className="tag" key={tag}> {tag} </span>
          )}
        </div>
        {hasHtmlTags ? (
          <div className="card-content" dangerouslySetInnerHTML={{ __html: description }}></div>
        ) : (
          <div className="card-content">
            <p>{description}</p>
          </div>
        )}
        {github && (<div className="card-footer">
          <a href={github}
          target="_blank"
          rel="noopener noreferrer"
          title="View this project on GitHub"
          className="link">
            <i className="icon-github"></i>
            <span>View on GitHub</span>
          </a>
          </div>
        )}
      </div>
    </div>
  )
}
