import React, { Component } from 'react';
import { keepTheme } from './utils/themes';
import Sidebar from './components/sidebar';
import Introduction from './components/introduction';
import About from './components/about';
import Education from './components/education';
import Experience from './components/experience';
import Skills from './components/skills';
import RecentWork from './components/recentWork';

class App extends Component {
  render() {
    keepTheme();
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	<Sidebar></Sidebar>
          <main id="colorlib-main">
            <Introduction></Introduction>
            <About></About>
            <Experience></Experience>
            <Education></Education>
            <Skills></Skills>
            <RecentWork></RecentWork>
            <div style={{"marginBottom": "4em"}}></div>
          </main>
      	</div>
      </div>
    );
  }
}

export default App;
