import React, { Component } from 'react';

export default class Timeline extends Component {
  render() {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="timeline-centered">
                    {this.props.items.map((item) => {return (
                        <article key={`timeline-card-${item.id}`} className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                            <div className="timeline-entry-inner">
                            <div className={`timeline-icon ${item.color}`}>
                                <i className={item.icon} />
                            </div>
                            <div className="timeline-label">
                                <h2> {item.title} <span> {item.timespan}</span>  <br/>
                                <span><i className="icon-location"></i> {item.location} </span>
                                </h2>
                                <p> {item.description} </p>
                            </div>
                            </div>
                        </article>
                    )})}
                    <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                        <div className="timeline-entry-inner">
                            <div className="timeline-icon color-none">
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
  }
}
