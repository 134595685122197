import React, { Component } from 'react';

export default class InfoCards extends Component {
  render() {
    return (
        <div className="row row-pt-md">
            {this.props.items.map((skill) => {
                return (
                <div key={`info-card-${skill.id}`} className="col-md-4 text-center animate-box">
                    <div className={`services ${skill.color}`}>
                        <span className="icon">
                            <i className={skill.icon} />
                        </span>
                        <div className="desc">
                            <h3> {skill.title} </h3>
                            <p> {skill.description} </p>
                        </div>
                    </div>
                </div>);
            })}
        </div>
    )
  }
}
