import React, { Component } from 'react';
import Timeline from './elements/timeline';
import workItems from "../data/work-experience.json";

export default class Experience extends Component {
  render() {
    return (
		<div>
			<section className="colorlib-work" data-section="experience">
			<div className="colorlib-narrow-content">
				<div className="row">
					<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
						<span className="heading-meta">Experience</span>
						<h2 className="colorlib-heading animate-box">Work</h2>
					</div>
				</div>
				<Timeline items={workItems}></Timeline>
			</div>
			</section>
		</div>
    )
  }
}
