import React, { Component } from 'react';
import ProjectCard from './elements/projectCard';
import projects from '../data/projects.json';

export default class RecentWork extends Component {
  render() {
    return (
        <section className="colorlib-work" data-section="projects">
            <div className="colorlib-narrow-content">
                <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                        <span className="heading-meta">My Work</span>
                        <h2 className="colorlib-heading animate-box">Projects</h2>
                    </div>
                </div>
                <div className="col-md-12 animate-box fadeInLeft">
                    <p>
                        During my studies, I had the opportunity to work on a variety of projects ranging from small-scale software applications to more complex systems. Through these projects, I was able to apply my knowledge of programming languages, software design principles, and project management techniques in somewhat real-world scenarios.
                    </p>
                    <p>
                        During my free time, I also enjoy working on personal projects to enhance my skills in software development. My focus is primarily on building web-based systems where I can practice and refine my expertise in JavaScript, React, HTML, and CSS. These projects allow me to explore and experiment with new methods and frameworks. By setting myself challenges and tackling complex problems, I am able to improve my problem-solving skills and creativity.
                    </p>
                </div>
                <div className="row cards">
                    {projects.map(project => <ProjectCard key={project.title} project={project}/>)}
                </div>
            </div>
        </section>
    )
  }
}
    