import React, { Component } from 'react'

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section id="colorlib-hero" className="js-fullheight" data-section="home">
          <div className="flexslider js-fullheight">
            <ul className="slides">
              <li style={{backgroundImage: 'url(images/img_bg.jpg)'}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1 className="light">Hi! <br />I'm Julian</h1>
                          <p><a className="btn btn-learn" href="https://www.linkedin.com/in/julian-egbert" target="_blank" rel="noopener noreferrer">View LinkedIn <i className="icon-linkedin2" /></a></p>
                        </div>
                      </div>
                    </div>
                    <div className="image-copyright-text"> Picture: Kay Herschelmann</div>
                  </div>
                </div>
              </li>
              <li style={{backgroundImage: 'url(images/Code.png)'}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner">
                        <div className="desc">
                          <h1 className="light">I love <br /> writing code</h1>
                          <p><a className="btn btn-learn" href="https://github.com/JulianEgbert" target="_blank" rel="noopener noreferrer">View GitHub <i className="icon-github" /></a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li style={{backgroundImage: 'url(images/TeachingBackground.jpg)'}}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                      <div className="slider-text-inner js-fullheight">
                        <div className="desc">
                          <h1 className="light">I am an <br />educator</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    )
  }
}
