function setTheme(themeName) {
    localStorage.setItem("theme", themeName);
    // Remove all theme classes if assigned
    const classes = Array.from(document.documentElement.classList);
    const themeClasses = classes.filter(el => el.startsWith("theme-"));
    if(themeClasses.length > 0)
        document.documentElement.classList.remove(themeClasses);
    // Add theme class
    document.documentElement.classList.add(themeName);
}

function keepTheme() {
  if (localStorage.getItem("theme")) {
    if (localStorage.getItem("theme") === "theme-dark") {
      setTheme("theme-dark");
    } else if (localStorage.getItem("theme") === "theme-light") {
      setTheme("theme-light")
    }
  } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    setTheme("theme-dark")
  } else {
    setTheme("theme-light")
  }
}

module.exports = {
  setTheme,
  keepTheme
}
