import React, { Component } from 'react';
import logo from '../reactLogo.svg';
import ThemeToggle from './themeToggle';

export default class Sidebar extends Component {
  render() {
    return (
      <div className="notranslate">
        <div>
          <nav href="#navbar" className="js-colorlib-nav-toggle colorlib-nav-toggle" data-toggle="collapse" data-target="#navbar" aria-controls="navbar"><i /></nav>
          <aside id="colorlib-aside" className="border js-fullheight">
            <div id="header" className="text-center notranslate">
              <div className="author-img" style={{backgroundImage: 'url(images/about.png)'}} />
              <h1 id="colorlib-logo"><span>Julian Egbert</span></h1>
              <span className="jobdescription"> Software Engineer</span>
              <span className="address"><i className="icon-location"></i> Berlin, Germany</span>
              <span className="email"><i className="icon-mail"></i> <a href="mailto:contact@julianegbert.de">contact@julianegbert.de</a></span>
            </div>
            <nav id="colorlib-main-menu" role="navigation" className="navbar">
              <div id="navbar">
                <ul>
                  <li className="active"><a href="#home" data-nav-section="home">Introduction</a></li>
                  <li><a href="#about" data-nav-section="about">About</a></li>
                  <li><a href="#experience" data-nav-section="experience">Experience</a></li>
                  <li><a href="#education" data-nav-section="education">Education</a></li>
                  <li><a href="#programming" data-nav-section="programming">Programming</a></li>
                  <li><a href="#projects" data-nav-section="projects">Projects</a></li>
                </ul>
                <ul className="icon-links">
                  <li><a href="https://www.linkedin.com/in/julian-egbert" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin2" /></a></li>
                  <li><a href="https://github.com/JulianEgbert" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i></a></li>
                </ul>
              </div>
            </nav>
            <ThemeToggle />
            <div className="colorlib-footer">
              <p><small>
                Built with <img src={logo} className="React-logo" alt="React logo" title="React"/> <br></br>
                Template adapted from Colorlib
              </small></p>
            </div>
          </aside>
        </div>
      </div>
    )
  }
}
