import React, { Component } from 'react'
import { FaGraduationCap, FaSchool } from 'react-icons/fa';

export default class Education extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="education">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                <h2 className="colorlib-heading animate-box">Education</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-custom"><FaGraduationCap/></i>
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Master of Science, IT-Systems Engineering <span>2022 - present</span> <br/>
                          <span><i className="icon-location"></i> Hasso-Plattner-Institute, Potsdam, Germany</span>
                        </h2>
                        <p>In October 2022 I started my Master's degree at the Hasso-Plattner-Institute. I have a special focus in the fields of "Internet, Security & Algorithm Engineering" and "Software Architecture & Modeling Technology". </p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInTop">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-4">
                        <i className="icon-custom"><FaGraduationCap/></i>
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Bachelor of Science, IT-Systems Engineering <span>2019 - 2022</span> <br/>
                          <span><i className="icon-location"></i> Hasso-Plattner-Institute, Potsdam, Germany</span>
                        </h2>
                        <p> From 2019 to 2022 I studied my Bachelor in IT-Systems Engineering at the Hasso-Plattner-Institute. I had a special focus in the fields of "Human Computer Interaction & Computer Graphics Technology" and "Operating Systems & Information Systems Technology".</p>
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry animate-box" data-animate-effect="fadeInLeft">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-5">
                        <i className="icon-custom"><FaSchool/></i>
                      </div>
                      <div className="timeline-label">
                        <h2>
                          High-school diploma <span>2011 - 2019</span> <br/>
                          <span><i className="icon-location"></i> Herder-Gymnasium, Berlin, Germany</span>
                        </h2>
                        <p>I have completed my high school education with a special focus in mathematics and computer science. During my time at high-school, I participated in several competitions in the field of mathematics and physics where I have been very successfull. You can find a brief list of those in my <a href="https://www.linkedin.com/in/julian-egbert" target="_blank" rel="noopener noreferrer">LinkedIn</a> profile.</p> 
                      </div>
                    </div>
                  </article>
                  <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none">
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
